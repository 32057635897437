@import "common/variables";
@import "common/mixins";

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/*
   General Styles
 */
body {
  position: relative;
  margin: 0;

  color: #444;
  font-family: sans-serif;
  font-size: 8pt !important;
}

img {
    max-width: 100%;
}

.container {
  width: 98%;
  margin: 0 auto;
}

/*
  Hide Elements
 */
.mp-trigger,
.banner-wrapper,
#nav_primary,
#breadcrumbs,
.easyzoom-flyout,
.legend,
#product-tabs .nav-tabs,
#nav_mobile,
.footer,
.js-toggle-print,
#bannerMenu {
  display: none!important;
}

.brand {
    max-width: 275px;
}

/*
  Product
 */
.productImage {
    width: 30%;
    float: left;
  }

  .productHeader,
  .productBody {
    width: 68%;
    float: right;
  }

  .product-skus {
  margin-bottom: $spacer;
  width: 100%;

  font-size: .92rem;
}

#product-detail {
  border: 1px solid #dde2e9;
  text-align: center;
}

.sku-list-header {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-bottom: 0;
}

.sku-list-header th {
  line-height: 1;
}

.sku-list-header th,
.sku-list td {
  padding: ($spacer * .833) ($spacer * .5);

  border-right: 1px solid $gray-400;
  vertical-align: top;

  &:last-child {
    border-right: 0;
  }
}

.skuItem {
  border-bottom: 1px solid $gray-400;

  &:last-child {
    border-bottom: 0;
  }

  .price-sale.discounted {
    color: #b21613;
  }
}

.skuItem-code {
  white-space: nowrap;
}

.sku-list {
  margin: 0 0 $spacer;
  padding: 0;

  border: 1px solid $gray-400;
  list-style: none;
}

.skuItem-title {
  font-size: 1rem;

  a {
    display: block;
    padding-bottom: $spacer;
  }
}

.price {
  text-align: right;
}

.skuItem {

  .price-sale {
    font-size: 1rem;
  }
}

.skuItem-code {
  width: 8rem;
}

.skuItem-actions {
  display: none;
}

.tax-info {
  text-align: left!important;
}

/*
  Cart
 */
.shoppingCart-index {
  .row {
    display: block;
  }

  .col-lg-9 {
    width: 100%;
    max-width: none;
  }

  .col-lg-3,
  #cart_checkout {
    display: none;
  }
}

.cart-header {
  display: flex;

  background-color: $gray-100;
}

.cart-header,
.cart-items {
  border: 1px solid $gray-200;
}

.cart-items {
  padding: 0;
}

.cart-item {
  & > div {
    border-right: 1px solid $gray-200;

    &:last-child {
      border-right: 0;
    }
  }
}

.cart-header,
.cart-item {
  & > div {
    padding: ($spacer * .5);
  }
}

.cart-item {
  display: flex;
  flex-wrap: nowrap;

  border-bottom: 1px solid $gray-200;
  text-align: right;

  &:last-child {
    border-bottom: 0;
  }

    .cart-item-qty,
    .cart-item-price {
      padding-top: ($spacer * 2);
    }

  .net_price {
    font-size: .75rem;
  }
}

.cart-item-name a {
  color: $brand-secondary;
  font-weight: bold;
  text-decoration: underline;
}

.cart-item-id {
  color: #92a8c5;
  font-size: .92rem;
}

.cart-item-options {
  margin: 0;
  padding: 0;

  font-size: .92rem;
  list-style: none;
}

.cart-item-img {
  text-align: center;
}

.cart-item-img {
  width: 100px;
  float: left;
}

.cart-item-info {
  margin-left: 100px;

  text-align: left;
}

.cart-item-qty,
.cart-item-price_amount,
.cart-item-price_total {
  text-align: right;
}

.cart-item-price_amount,
.cart-item-price_total {
  display: inline-block;
}

.cart-item-img {
  width: 10%;
  float: none;
}

.cart-item-info {
  width: 40%;
  margin-left: 0;
}

.cart-item-qty {
  width: 20%;
}

.cart-item-price_amount,
.cart-item-price_total {
  width: 15%;
}

.cart-calc,
.cart-summary {
  font-size: .92rem;
  padding-left: 40%;

  & > div {
    display: flex;
    margin-bottom: $spacer;
    padding: ($spacer * .5) 0;
  }
}

.cart-summary-label {
  flex-grow: 1;
  text-align: right
}

.cart-summary-item {
  width: 8rem;
  flex-shrink: 0;

  text-align: right;

  .cart-summary-item {
    width: 10rem;
  }
}

.cart-calc-subtotal {
  font-size: 1.1rem;
}

.cart-calc-subtotal-info {
  font-size: .92rem;
}

.cart-calc-subtotal {
  border-bottom: 1px solid $gray-200;
}

.cart-summary-total {
  border-bottom: 3px double $gray-200;
  font-size: 1.2rem;
}

.cart-summary {
  .cart-checkout {
    display: block;

    text-align: right;
  }
}

.quantity {
  display: block;
  margin: 0 auto;
  width: auto;

  text-align: center;

  &.qtyBtn {
    display: none;
  }
}

.btn-remove {
  display: none;
}

/*
  Footer
 */
// Hide footer elements in .shop-information, except address
#shop-features,
#shop-about,
#shop-customer-information,
#shop-customer-juridical,
#shop-payment-methods,
#shop-contact,
.social-links,
.shop-information hr {
  display: none;
}

address {
  position: fixed;
  top: 1.5em;
  right: 1.5em;
}

address div:first-child {
  font-weight: bold;
  color: #8ebe00;
}

address .postal-code,
address .city {
  display: inline-block;
}

address .mail a {
  text-decoration: none;
}

.visible-print {
  display: block;
}
